import React from "react";
import SEO from "../common/SEO";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

import ContactOne from "../elements/contact/ContactOne";

const Contato = () => {
  return (
    <>
      <SEO title="Contato" />
      <main className="page-wrapper">
        <div className="header-transparent-with-topbar">
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="especial-header"
          />
        </div>
        {/* Start Elements Area  */}

        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container" style={{ marginTop: "7rem" }}>
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="Entre em contato conosco"
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default Contato;
