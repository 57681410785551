import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

const TabThree = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Odontologia</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Corpo e Mente</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Estomaterapia</button>
                        </div>
                      </Tab>
                      {/* <Tab>
                        <div className="rn-tab-button">
                          <button>Estética Facial</button>
                        </div>
                      </Tab> */}
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Salas para Cursos</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div>
                            <div>
                              <div
                                className="section-title"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                {/* <h4 className="title">How can we help you?</h4> */}
                                <p>
                                  Nosso Espaço conta com Odontologia e oferece
                                  uma gama completa de serviços atendendo todas
                                  as especialidades odontológicas, Os procedimentos são
                                  realizados por profissionais altamente
                                  qualificados. Sua saúde é fundamental para o
                                  seu bem-estar geral, e todos estão
                                  comprometidos em mantê-la em ótimas condições.
                                </p>
                                <div
                                  id="detalhes"
                                  style={{ gap: "5px", display: "flex" }}
                                  className="read-more"
                                >
                                  <a
                                    style={{
                                      width: "250px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="btn-default btn-small"
                                    href="https://odontologiawolcoff.com.br"
                                  >
                                    Odontologia Wolcoff
                                  </a>
                                  <br />
                                  <a
                                    id="spacing"
                                    style={{
                                      width: "250px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="btn-default btn-small"
                                    href="https://natalietemporao.com.br/"
                                  >
                                    Dra. Natalie Temporão
                                  </a>
                                  <br />
                                  <a
                                    style={{
                                      width: "250px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="btn-default btn-small"
                                    href="https://instagram.com/dra.gisimiao?igshid=MzRlODBiNWFlZA=="
                                  >
                                    Dra. Giovanna Simião
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div>
                            <div>
                              <div
                                className="section-title"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                {/* <h4 className="title">
                                  Whice Service Provided?
                                </h4> */}
                                <p>
                                  O Instituto Heuko também está presente no
                                  nosso Espaço. Com atendimentos nas
                                  especialidades de massoterapia, hipnoterapia e
                                  ozonioterapia. Com terapeutas especializados
                                  em massoterapia proporcionam alívio do
                                  estresse e relaxamento profundo por meio de
                                  massagens terapêuticas, e tratamentos voltados
                                  para a massoterapia estética. Cada sessão é
                                  projetada para atender às suas necessidades
                                  específicas e melhorar sua qualidade de vida.
                                  A Hipnoterapia é uma técnica poderosa para
                                  ajudar a superar desafios mentais e
                                  emocionais. Com hipnoterapeutas certificados
                                  trabalham com você para alcançar metas,
                                  superar medos e promover mudanças positivas em
                                  sua vida. A ozonioterapia é uma abordagem
                                  inovadora que utiliza o ozônio para promover a
                                  cura e melhorar a saúde. Com profissionais
                                  treinados oferecem tratamentos seguros e
                                  eficazes para uma variedade de condições
                                  médicas.
                                </p>
                                <div className="read-more">
                                  <a
                                    style={{
                                      display: "flex",
                                      width: "200px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="btn-default btn-small"
                                    href="https://instagram.com/heukomassoterapiaehipnoterapia?igshid=MzRlODBiNWFlZA=="
                                  >
                                    Saiba mais
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div>
                            <div>
                              <div
                                className="section-title"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                {/* <h4 className="title">
                                  Whice Service Provided?
                                </h4> */}
                                <p>
                                  Oferecemos especialistas em estomaterapia para
                                  cuidados em estomas, feridas, e podiatria.
                                  Além disso, fornecemos cursos de atualização
                                  para profissionais de saúde e consultoria em
                                  prevenção de complicações pós-operatórias e
                                  auditoria de contas relacionadas ao tratamento
                                  de feridas.
                                </p>
                                <div className="read-more">
                                  <a
                                    style={{
                                      display: "flex",
                                      width: "200px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="btn-default btn-small"
                                    href="https://www.facebook.com/specialistcarecuritiba"
                                  >
                                    Saiba mais
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div>
                            <div>
                              <div
                                className="section-title"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                {/* <h4 className="title">
                                  Whice Service Provided?
                                </h4> */}
                                <p>
                                  No Espaço Wolcoff, não apenas nos preocupamos
                                  com o seu bem-estar físico, mas também estamos
                                  comprometidos em apoiar o seu desenvolvimento
                                  pessoal e profissional. É por isso que
                                  oferecemos salas de cursos modernas e bem
                                  equipadas para locação, proporcionando o
                                  ambiente perfeito para a disseminação do
                                  conhecimento e o crescimento pessoal.
                                </p>
                                <div className="read-more">
                                  <a
                                    style={{
                                      display: "flex",
                                      width: "200px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className="btn-default btn-small"
                                    href="/salas-de-aula"
                                  >
                                    Saiba mais
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabThree;
