import React, { useState } from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Modal, Box, Typography } from "@mui/material";

const ceoData = [
  {
    image: "team-01",
    name: "Wolcoff Odontologia",
    designation: "Saude",
    location:
      "Wolcoff Odontologia, especializada em cuidados odontológicos de excelência",
    description: `Fundada em 2013, a Odontologia Wolcoff tem sido referência no bairro Portão ao longo desses 10 anos e em 2023 expandimos nossa clínica também para o bairro Ahú. Nossa equipe é composta por especialistas altamente qualificados e experientes em diversas áreas da odontologia.

    Somos uma equipe dedicada de profissionais apaixonados pela saúde bucal e comprometidos em proporcionar cuidados odontológicos excepcionais para todos os nossos pacientes. Na Odontologia Wolcoff, nossa missão é transformar sorrisos e melhorar vidas por meio da excelência em odontologia.
    `,
    link: "https://www.odontologiawolcoff.com.br/",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
];

const teamData = [
  {
    image: "team-02",
    name: "Specialist Care",
    designation: "Saude",
    location:
      "Uma equipe experiente em estomaterapia que oferece cuidados, cursos de atualização e consultorias em prevenção de complicações de feridas, atendendo a diversas necessidades médicas, com profissionais especializados em várias áreas de saúde.",
    description: `Uma equipe experiente em estomaterapia que cuida de estomas, feridas, podiatria e previne lesões em pacientes acamados, doenças vasculares e diabéticos, incluindo casos raros como epidermólise bolhosa. Oferecemos cursos de atualização para profissionais de saúde e serviços de consultoria em prevenção de complicações de feridas pós-operatórias e auditoria de contas de tratamento de feridas.    
    
    Ana Paula Hey: é enfermeira estomaterapeuta, Ti SOBEST Podiatra, especialista em terapia intensiva, cuidados paliativos, mestre em cirurgia e mestre em cuidados paliativos.

    Viviane Kayser: é enfermeira estomaterapeuta, com especialização em auditoria em serviços de saúde e projetos assistenciais de saúde.

    Ketlin Wolcoff: é enfermeira estomaterapeuta, com especializações em administração em saúde, saúde da família e saúde pública.
`,
    link: "https://www.facebook.com/specialistcarecuritiba/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  {
    image: "team-03",
    name: "Instituto Nestor Heuko",
    designation: "Saude",
    location:
      "Nestor e Alzira de Fátima Heuko, estão à frente do Instituto Nestor Heuko de Hipnose e Bem-estar há cerca de 25 anos, trabalhando com Hipnose e Massoterapia.",
    description: `Nestor e Alzira de Fátima Heuko, estão à frente do Instituto Nestor Heuko de Hipnose e Bem-estar há cerca de 25 anos, trabalhando com Hipnose e Massoterapia.
    
    Nestor, terapeuta desde 1996, atua na hipnoterapia desde 2007 e valoriza o poder da mente humana. Suas formações incluem técnico em massoterapia, coaching e várias especializações em hipnose.
    
    Alzira, natural de Curitiba, se dedica desde 2001 à constelação familiar, hipnoterapia e massoterapia, visando proporcionar bem-estar a pacientes com dores, ansiedade, estresse e depressão. Suas formações incluem técnica em massoterapia, especialização em florais, terapia oriental, pedagogia, hipnose, psicologia positiva e pós-graduação em psicoterapia transgerativa com ênfase em hipnose.`,

    link: "https://www.instagram.com/heukomassoterapiaehipnoterapia/",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  // {
  //   image: "team-04",
  //   name: "Kamila Santos",
  //   designation: "Saude",
  //   location:
  //     "Oferecemos Estética Facial com Kamila, Seus serviços incluem Design de Sobrancelhas Personalizado, Limpeza de Pele, Brow Lamination, Lash Lifting e Dermaplaning. Sua missão é realçar sua beleza de forma natural. Desfrute de uma experiência única!...",
  //   description: ` Meu nome é Kamila, 35 anos, casada, mãe da Laura e do Arthur. Sou formada em Secretariado Executivo, trabalhei na área administrativa desde os meus 17 anos. Meus últimos anos de CLT foi no mercado segurador. Sempre amei meu trabalho e o fazia com muita dedicação. Em 2019 mudamos de estado, onde minha transferência não foi possibilitada, nesse momento dei vida a um sonho antigo, a Estética e o empreendedorismo. 
  //   Iniciei alguns cursos livres em primeiro momento, fiz várias especializações e nesse ano me formei em Estética e Cosmética. Estou sempre buscando atualizações para entregar sempre o melhor para minhas clientes. Amo o que eu faço, meu trabalho é minha paixão e minha missão é realçar sua beleza com naturalidade. 
  //   Desejo que nosso encontro seja uma verdadeira experiencia. Meus serviços disponíveis são: Design de Sobrancelhas Personalizado Limpeza de Pele Brow Lamination Lash Lifting Dermaplaning Consultoria em Skincare`,

  //   link: "https://www.instagram.com/kamilasantos.beauty/",
  //   socialNetwork: [
  //     {
  //       icon: <FiFacebook />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiTwitter />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiInstagram />,
  //       url: "#",
  //     },
  //   ],
  // },
  {
    image: "team-05",
    name: "Dra. Natalie Temporão",
    designation: "Saude",
    location:
      "Implantodontista e Periodontista ",
    description: `  Sou cirurgiã-dentista graduada pela PUC-PR em 2012, Desde o início sempre fui apaixonada pela área de periodontia e cirurgia. Após minha graduação, cursei Especialização em Periodontia em Porto Alegre/RS, na Universidade Federal do Rio Grande do Sul. 
    Segui na área, me tornando mestre em Odontologia com Ênfase em Periodontia pela Universidade Federal do Paraná. Sou especialista em Implantodontia pelo ILAPEO, grande referência na área. Possuo também pósgraduação em cirurgia-oral menor e cirurgia periodontal e periimplantar. Sigo com meus estudos e cursos para estar sempre me atualizando, levando para meus pacientes um tratamento de excelência. Sou extremamente grata por esta profissão maravilhosa, que me permite trazer de volta a saúde e o sorriso para as pessoas! Atendo cada um dos meus pacientes com muito carinho, respeito e transparência. E agradeço a cada um por me confiar sua saúde bucal. 
    
    Dra. Natalie Temporão CROPR 22.968`,

    link: "https://natalietemporao.com.br/",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  {
    image: "team-06",
    name: "Dra. Giovanna Simião",
    designation: "Saude",
    location:
      "Ortodontista e especialista em Invisalign",
    description: `  Sou cirurgiã-dentista graduada pela Pontifícia Universidade Católica do Paraná em 2012. Em 2015, concluí minha especialização em ortodontia e, em 2016, me graduei mestre na mesma disciplina, também pela PUCPR.
    Minha paixão pela ortodontia me levou a estudar muito e a buscar o melhor tratamento ortodôntico para os meus pacientes. Por isso, me credenciei como Invisalign Top Doctor e, hoje, trabalho com a ortodontia digital. Tenho muito amor pela minha profissão e muito respeito pelos meus pacientes. Sei que por trás de cada sorriso que transformo, diariamente, existe uma história de vida única.
    
    Dra. Giovanna Simião Especialista e mestre em Ortodontia CRO 22.958 PR
    `,

    link: "https://www.instagram.com/dra.gisimiao/",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  {
    image: "team-07",
    name: "Mairon Schlichting",
    designation: "Saude",
    location:
      "Nutricionista e Personal Trainer",
    description: `Eu sou Mairon, um entusiasta da saúde, nutrição e esportes, com mais de 20 anos de experiência. Graduado em Educação Física e Nutrição, com especializações em Nutrição Esportiva e Fisiologia do Esporte, ofereço consultoria esportiva e orientação nutricional personalizada. Esteja você buscando promover a saúde, perder peso, ganhar massa muscular, passar por cirurgia bariátrica, adotar uma dieta vegana ou enfrentar desafios durante a gestação, estou aqui para ajudar. Minha missão é guiá-lo, independentemente da idade ou ponto de partida, em direção a uma vida mais saudável. Junte-se a mim nessa jornada emocionante em busca do seu melhor. Vamos alcançar juntos um estilo de vida mais saudável e equilibrado.`,

    link: "https://nutrimairon.com.br/",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
];

const TeamFour = ({ column, teamStyle }) => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({ name: "", description: "" });

  const handleOpen = (data) => {
    setOpen(true);
    setModalData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    maxWidth: 600,
    width: "100%",
    margin: "auto",
    mt: 6,
    p: 4,
    backgroundColor: "#0996a0d9",
  };

  return (
    <div
      className="row row--15"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* Renderiza a seção do CEO */}
      {ceoData.map((data, index) => (
        <div
          className="col-lg-6 col-xl-4 col-md-6 col-12 mt--30"
          key={index + 1}
        >
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`rn-team ${teamStyle}`}>
              <div className="inner">
                <figure className="thumbnail">
                  <img
                    src={`./images/team/${data.image}.jpg`}
                    alt="Equipe imagem"
                  />
                </figure>
                <figcaption className="content">
                  <div className="team-info">
                    <h2 className="title">{data.name}</h2>
                    {/* <h6 className="subtitle theme-gradient">
                      {data.designation}
                    </h6> */}
                    <div className="team-form">
                      <span className="location">{data.location}</span>
                      <Button
                        sx={{ color: "white" }}
                        onClick={() => handleOpen(data)}
                      >
                        Ver mais...
                      </Button>
                    </div>
                  </div>
                </figcaption>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontSize: "18px",
              borderBottom: "1px solid #fff",
              paddingBottom: "4px",
            }}
            variant="h6"
            component="h2"
          >
            {modalData.name}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: "16px", color: "#fff" }}
          >
            {modalData.description.split("\n").map((paragraph, index) => (
              <p key={index} style={{ color: "#fff" }}>
                {paragraph}
              </p>
            ))}
            <a href={modalData.link} target="_blank" rel="noopener noreferrer">
        <button className="btn-default btn-icon">Saiba mais</button>
      </a>
          </Typography>
        </Box>
      </Modal>

      {/* Renderiza a seção da equipe (abaixo da seção do CEO) */}
      <div
        className="row row--15"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {teamData.map((data, index) => (
          <div
            className="col-lg-6 col-xl-4 col-md-6 col-12 mt--30"
            key={index + 1}
          >
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div
                className={`rn-team ${teamStyle}`}
                // style={{ width: "200px" }}
              >
                <div className="inner">
                  <figure className="thumbnail">
                    <img
                      src={`./images/team/${data.image}.jpg`}
                      alt="Wolcoff"
                    />
                  </figure>
                  <figcaption className="content">
                    <div className="team-info">
                      <h2 className="title">{data.name}</h2>
                      {/* <h6 className="subtitle theme-gradient">
                        {data.designation}
                      </h6> */}
                      <div className="team-form">
                        <span className="location">{data.location}</span>
                        <Button
                          sx={{ color: "white" }}
                          onClick={() => handleOpen(data)}
                        >
                          Ver mais...
                        </Button>
                      </div>
                    </div>
                  </figcaption>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamFour;
