import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "/images/wolcoff/banner/ESTRUTURA.png",
    title: "Estrutura",
    description:
      "Nosso Espaço é mais do que apenas um lugar para tratamentos de saúde, é um refúgio de tranquilidade, cura e rejuvenescimento. Queremos que você conheça a nossa estrutura, onde todos os detalhes foram cuidadosamente projetados para proporcionar uma experiência única de bem-estar.",
  },
  {
    image: "/images/wolcoff/banner/AMBIENTE ACOLHEDOR.png",
    title: "Ambiente Acolhedor e Relaxante",
    description:
      "Ao entrar em nossa clínica, você será recebido por um ambiente acolhedor e relaxante. Acreditamos que o ambiente desempenha um papel fundamental em seu processo de cura. Nossos espaços são projetados para criar uma sensação de calma e serenidade, ajudando a aliviar o estresse desde o momento em que você chega.",
  },
  {
    image: "/images/wolcoff/banner/sala-de-tratamento-moderno.png",
    title: "Salas de Tratamento Modernas",
    description:
      "Nossa sala de tratamento são equipadas com tecnologias modernas e são mantidas com os mais altos padrões de higiene e conforto. Cada sala foi projetada para atender às necessidades específicas de nossos tratamentos, proporcionando um ambiente seguro e eficaz para sua jornada de saúde.",
  },
  {
    image: "/images/wolcoff/banner/FACILIDADES.png",
    title: "Facilidades",
    description:
      "Nossa clínica conta com facilidades, incluindo áreas de espera confortáveis, estacionamento e fácil acesso. Queremos que sua visita seja conveniente e livre de estresse, para que você possa se concentrar em seu bem-estar.",
  },
];
const ServiceTwo = ({ textAlign, cardStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`card-box ${cardStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <Link to="#service">
                    <img src={`${val.image}`} alt="card Images" />
                  </Link>
                </div>
                <div className="content">
                  <h4 className="title mb--20">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                  {/* <Link
                    className="btn-default btn-small btn-border"
                    to="#service"
                  >
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceTwo;
