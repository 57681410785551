import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabTwo = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <img
                  className="radius-small"
                  src="./images/wolcoff/banner/quemsomos.png"
                  alt="Wolcoff"
                />
              </div>
              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="rn-default-tab style-two">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Quem Somos</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Nossa Missão</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Espaço Wolcoff</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Somos uma equipe dedicada de profissionais de saúde
                            apaixonados por promover o bem-estar integral de
                            nossos pacientes. Nossos especialistas em diversas
                            áreas da saúde trabalham em conjunto para oferecer
                            uma abordagem completa para o seu cuidado, com foco
                            na prevenção, tratamento e educação.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            No Espaço Wolcoff, nossa missão é melhorar a
                            qualidade de vida de nossos pacientes por meio de
                            cuidados de saúde excepcionais, educação e promoção
                            do bem-estar. Nossa abordagem integrada visa tratar
                            não apenas os sintomas, mas também as causas
                            subjacentes de problemas de saúde, para que você
                            possa desfrutar de uma vida mais saudável e feliz.
                            Estamos comprometidos em ser um recurso confiável
                            para sua jornada de saúde e bem-estar,
                            proporcionando um ambiente acolhedor e um
                            atendimento personalizado.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Estamos aqui para apoiar a sua jornada de saúde e
                            bem-estar. Junte-se a nós e dê o primeiro passo em
                            direção a uma vida mais saudável, equilibrada e
                            plena. Aqui o seu bem-estar é a nossa missão, e a
                            sua saúde é a nossa prioridade.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabTwo;
