import React from "react";
import SEO from "../common/SEO";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import HeaderOne from "../common/header/HeaderOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import TeamFour from "../elements/team/TeamFour";
import Separator from "../elements/separator/Separator";


const Especialidades = () => {
  return (
    <>
      <SEO title="Parceiros" />

      <HeaderOne
        btnStyle="btn-small round btn-icon"
        HeaderSTyle="especial-header"
      />

      <div className="main-content">
        <div className="rwt-team-area rn-section-gap">
          <div className="wrapper plr--65 plr_sm--15">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Nossa Equipe <br/>Dedicados à Sua Saúde e Bem-Estar!"
                  description="Contamos com uma equipe de profissionais altamente qualificados e experientes, <br/>incluindo dentistas especializados e terapeutas de bem-estar comprometidos com a sua saúde global."
                />
              </div>
            </div>
            <TeamFour
              column="col-lg-3 col-xl-3 col-md-4 col-12 mt--30"
              teamStyle="team-style-three"
            />
            <br />

          </div>
        </div>
      </div>
      <FooterTwo />
      <Copyright />
    </>
  );
};
export default Especialidades;
