import React, { useState } from "react";
import ContactForm from "./ContactForm";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";

const ContactOne = () => {
  const [isSedeAhu, setIsSedeAhu] = useState(false);

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones color="black" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Telefone</h4>
                    <p>
                      <a href="tel:+4132064241">Tel: (41) 3206-4241</a>
                    </p>
                    <p>
                      <a href="tel:+41997679426">WhatsApp: (41) 99767-9426</a>
                    </p>
                    <p>
                      <a style={{ fontSize: "16px" }}>
                        Telefone e WhatsApp Ahú:(41) 4106-5970{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail color="black" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Endereço de E-mail</h4>
                    <p>
                      <a href="mailto:admin@gmail.com">
                        espacowolcoff@gmail.com
                      </a>
                    </p>
                    <p>
                      <a href="mailto:example@gmail.com">&nbsp;</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin color="black" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Localização</h4>
                    <p>
                      <b style={{ fontSize: "20px" }}>Sede Portão:</b> <br />
                      <span style={{ fontSize: "14px" }}>
                        R. Dr. Lauro Wolff Valente, 176 - Portão, Curitiba PR,
                        81070-010
                      </span>
                    </p>
                    <p style={{ marginTop: "10px" }}>
                      <b style={{ fontSize: "20px" }}>Sede Ahú:</b>
                      <br />
                      <span style={{ fontSize: "14px" }}>
                        R. Alberto Folloni, 1712 - Ahú, Curitiba PR, 82200-230
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30 col-12">
          <div className="d-flex gap-3 form-group flex-wrap">
            <button
              onClick={() => setIsSedeAhu(false)}
              className="btn-default btn-small"
            >
              Sede Porão
            </button>
            <button
              onClick={() => setIsSedeAhu(true)}
              className="btn-default btn-small"
            >
              Sede Ahú
            </button>
          </div>
          {/* <GoogleMapStyle /> */}
          <div className="map-container">
            {!isSedeAhu ? (
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d246.17274072549688!2d-49.297204132838694!3d-25.473887719508028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1swolcof%20dentista!5e0!3m2!1spt-BR!2sbr!4v1696254241752!5m2!1spt-BR!2sbr"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d225.25838806794565!2d-49.26237342375461!3d-25.400317777790825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce78d50dedd19%3A0xac130fc77ddfa268!2sOdontologia%20Wolcoff%20%2F%20Espa%C3%A7o%20Wolcoff%20-%20Sa%C3%BAde%20%26%20Bem-estar!5e0!3m2!1spt-BR!2sbr!4v1697467763273!5m2!1spt-BR!2sbr"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactOne;
