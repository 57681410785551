import React from "react";
import VideoTwo from "../video/VideoTwo";
import { FiCheck } from "react-icons/fi";

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: ["/video/videowolcoff.mp4"],
  },
];

const AboutFour = ({ image }) => {
  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            {PopupData.map((item) => (
              <div className="video-btn" key={item.id}>
                <VideoTwo imagename={`${image}`} galleryItem={item} />
              </div>
            ))}
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="title">
                  Bem-vindo ao Espaço Wolcoff <br />{" "}
                  <strong>Saúde e Bem-estar</strong>
                </h3>
                <ul className="feature-list">
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">
                        No Espaço Wolcoff, acreditamos que o bem-estar é uma
                        jornada que envolve cuidados holísticos para o corpo e a
                        mente
                      </h4>
                      <p className="text"></p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">
                        Nossa clínica foi fundada com a visão de proporcionar um
                        ambiente acolhedor e integrado, onde nossos pacientes
                        possam encontrar uma variedade de serviços de saúde de
                        alta qualidade para atender às suas necessidades
                        individuais
                      </h4>
                      <p className="text"></p>
                    </div>
                  </li>
                </ul>
                <div className="about-btn mt--30">
                  <a className="btn-default" href="https://api.whatsapp.com/send?phone=5541997679426&text=Ol%C3%A1,%20Gostaria%20de%20saber%20mais%20sobre%20a%20Espa%C3%A7o%20Wolcoff.">
                    Agende agora uma visita!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
