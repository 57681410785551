import React from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import FooterFour from "../common/footer/FooterFour";
import BrandThree from "../elements/brand/BrandThree";
import AboutFour from "../elements/about/AboutFour";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import TabTwo from "../elements/tab/TabTwo";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import GalleryOne from "../elements/gallery/GalleryOne";


const PopupData = [
  {
    id: "1",
    image: "./images/wolcoff/estrutura/estrutura01.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura01.png"],
  },
  {
    id: "2",
    image: "./images/wolcoff/estrutura/estrutura02.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura02.png"],
  },
  {
    id: "3",
    image: "./images/wolcoff/estrutura/estrutura03.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura03.png"],
  },
  {
    id: "4",
    image: "./images/wolcoff/estrutura/estrutura04.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura04.png"],
  },
  {
    id: "5",
    image: "./images/wolcoff/estrutura/estrutura05.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura05.png"],
  },
  {
    id: "6",
    image: "./images/wolcoff/estrutura/estrutura06.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura06.png"],
  },

  {
    id: "7",
    image: "./images/wolcoff/estrutura/estrutura07.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura07.png"],
  },

  {
    id: "8",
    image: "./images/wolcoff/estrutura/estrutura08.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura08.png"],
  },

  {
    id: "9",
    image: "./images/wolcoff/estrutura/estrutura09.png",
    popupLink: ["./images/wolcoff/estrutura/estrutura09.png"],
  },
];

const AboutUs = () => {
  return (
    <>
      <SEO title="Sobre" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          id="aboutus"
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/wolcoff/banner/BANNER-CONHEÇA-NOSSA-HISTÓRIA.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span style={{ color: "#05aab3" }}>
                        Conheça nossa história:
                      </span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    Dedicada a transformar <br /> Sorrisos e Vidas
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <div
          className="rwt-timeline-area rn-section-gapBottom"
          style={{ marginTop: "8rem" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <AboutFour image="./images/wolcoff/banner/bemvindoespacowolcoff.png" />

        <Separator />

        <div className="rwt-tab-area rn-section-gap mt--40">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Missão"
                  title="Quem Somos"
                  description=""
                />
              </div>
            </div>
            <TabTwo />
          </div>
        </div>

        <Separator />
        <div className="rwt-elements-area rn-section-gap">
          <div className="container">
            <div className="row mb--25">
              <div
                className="col-lg-10 offset-lg-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
              </div>
            </div>
            <div className="col-lg-11 offset-lg-2 mt--10">
            </div>
          </div>
        </div>
        <div
          className="service-area rn-section-gapTop"
          style={{ marginBottom: "9rem" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    Encontre o equilíbrio perfeito entre saúde e bem-estar em um
                    só lugar!
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Oferecemos uma ampla gama de serviços para atender às suas
                  necessidades de saúde bucal e bem-estar. Agende sua consulta e
                  transforme sua vida hoje mesmo!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
                <div className="row">
                  <div className="col-lg-12 mb--20">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Confira o nosso espaço"
                      title="Espaço Wolcoff"
                      description=""
                    />
                  </div>
                </div>
                <div className="row mt_dec--30 row--15">
                  {PopupData.map((item) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                      key={item.id}
                    >
                      <GalleryOne galleryItem={item} />
                    </div>
                  ))}
                </div>
              </div>
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;
