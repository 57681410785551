import React from "react";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from "./itemProp/BlogProp";
import HeaderOne from "../../common/header/HeaderOne";
import FooterTwo from "../../common/footer/FooterTwo";
import Copyright from "../../common/footer/Copyright";

const BlogGridView = () => {
  return (
    <>
      <SEO title="Blog" />
      <HeaderOne
        btnStyle="btn-small round btn-icon"
        HeaderSTyle="especial-header"
      />

      <BreadcrumbOne
        title="Fique por dentro de tudo sobre <br/>odontologia com nosso Blog"
        rootUrl="/"
        parentUrl="Home"
        currentUrl="Blog"
      />
      <div className="main-content">
        {/* Start Blog Area  */}
        <div className="rn-blog-area rn-section-gap">
          <div className="container">
            <div className="row mt_dec--30">
              <BlogProp
                column="col-lg-4 col-md-6 col-12 mt--30"
                StyleVarProp="box-card-style-default"
              />
            </div>
          </div>
        </div>
        {/* End Blog Area  */}
      </div>
      <FooterTwo />
      <Copyright />
    </>
  );
};
export default BlogGridView;
