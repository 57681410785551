import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

import HeaderOne from "../common/header/HeaderOne";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";

import ServiceOne from "../elements/service/ServiceOne";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlpitOne from "../elements/split/SlpitOne";
import TeamFour from "../elements/team/TeamFour";
import BrandTwo from "../elements/brand/BrandTwo";
import AdvanceTabOne from "../elements/advancetab/AdvanceTabOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import TabThree from "../elements/tab/TabThree";

const Company = () => {
  return (
    <>
      <SEO title="Home" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="rn-header header-default header-transparent new-header"
        />
        <div
          className="slider-area slider-style-2 height-950 bg_image"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/wolcoff/banner/homeHeaderBg.png)`,
            paddingTop: "0px",
          }}
          id="backgroundhome"
        >
          <div className="container new-header-content">
            <div id="mobtitle"  className="row">
              <div className="col-lg-12">
                <div className="inner text-start">
                  <div className="" style={{ position: "relative" }}>
                    <h4
                      className="new-subtitle"
                      style={{ position: "absolute" }}
                    >
                      <span id="espaco" className="new-theme-gradient">
                        ESPAÇO
                      </span>
                    </h4>
                    <h1
                      id="titulo"
                      style={{ color: "#8b4397" }}
                      className="title display-one new-title"
                    >
                      WOLCOFF
                    </h1>
                  </div>
                  <ul className="list-icon">
                    <li style={{ color: "#00aab3", maxWidth: "476px" }}>
                      <span style={{ color: "white" }}></span>
                      Bem-vindo ao Espaço Wolcoff, onde saúde e bem-estar se
                      encontram. Descubra nossas especialidades e equipe
                      dedicada para cuidar de você.
                    </li>
                    {/* <li style={{ color: "white" }}>
                      <span
                        className="icon"
                        style={{ backgroundColor: "#00aab3" }}
                      >
                        <FiCheck color="white" />
                      </span>{" "}
                      Salas de Tratamento Modernas
                    </li>
                    <li style={{ color: "white" }}>
                      <span
                        className="icon"
                        style={{ backgroundColor: "#00aab3" }}
                      >
                        <FiCheck color="white" />
                      </span>{" "}
                      Facilidades
                    </li> */}
                  </ul>
                  <div className="button-group mt--40 mt_sm--20">
                    <Link to="/sobre">
                      <button
                        className="btn-default btn-icon"
                        target="_blank"
                        href=""
                      >
                        Saiba Mais{" "}
                        <i className="icon">
                          <FiArrowRight />
                        </i>
                      </button>
                      <link
                        rel="stylesheet"
                        href="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-button.css"
                      ></link>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Slider Area  */}
        {/* <div className="slider-area slider-style-1 bg-transparent height-750">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <span className="subtitle">Espaço Wolcoff</span>
                  <h1 className="title theme-gradient display-one">
                    Saúde e Bem Estar
                  </h1>
                  <ul className="list-icon">
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{" "}
                      Ambiente Acolhedor e Relaxante
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{" "}
                      Salas de Tratamento Modernas
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{" "}
                      Facilidades
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                <div className="frame-image">
                  <img src="./images/about/about-3.png" alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Slider Area  */}

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Espaço Wolcoff"
                  description="Nosso espaço é um refúgio de tranquilidade e cura, onde cada detalhe foi projetado<br/> para criar uma experiência única de bem-estar além dos tratamentos de saúde."
                />
              </div>
            </div>

            {/* <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            /> */}
            <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        <div style={{ marginBottom: "10rem" }}>
          <SlpitOne />
        </div>

        <Separator />
        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Espaço Wolcoff"
                  title="Um pouco sobre nossos serviços"
                  description=""
                />
              </div>
            </div>
            <TabThree />
          </div>
        </div>
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default Company;
