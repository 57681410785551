import React from "react";
import { Link } from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];

const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;

const FooterTwo = () => {
  return (
    <>
      <footer className="rn-footer footer-style-default variation-two">
        {/* <CalltoActionSeven /> */}
        <div className="footer-top">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title" style={{ color: "#e4e6ea" }}>
                    {footerIntemOne.title}
                  </h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {indexOneLink.map((data, index) => (
                        <li key={index}>
                          <Link to={`${data.url}`}>{data.text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title" style={{ color: "#e4e6ea" }}>
                      {footerIntemTwo.title}
                    </h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexThreeLink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title" style={{ color: "#e4e6ea" }}>
                    {footerIntemThree.title}
                  </h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {indexTwoLink.map((data, index) => (
                        <li key={index}>
                          <a href={`${data.url}`}>{data.text}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              {/* <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">{footerIntemFour.title}</h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {indexTwoLink.map((data, index) => (
                        <li key={index}>
                          <Link to={`${data.url}`}>{data.text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div> */}
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title" style={{ color: "#e4e6ea" }}>
                    {footerIntemFive.title}
                  </h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <a style={{ color: "#e4e6ea" }}>
                        <b>Sede Portão</b>
                        <br /> R. Dr. Lauro Wolff Valente, 176 - Portão
                        Curitiba, PR
                      </a>
                      <a style={{ color: "#e4e6ea", marginTop: "10px" }}>
                        <b>Sede Ahú</b>
                        <br /> R. Alberto Folloni, 1712 Ahú, Curitiba, PR
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterTwo;
