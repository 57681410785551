import React from "react";
import SEO from "../common/SEO";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import HeaderOne from "../common/header/HeaderOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TabThree from "../elements/tab/TabThree";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import AdvanceTabOne from "../elements/advancetab/AdvanceTabOne";
import TabOne from "../elements/tab/TabOne";

const Especialidades = () => {
  return (
    <>
      <SEO title="Especialidades" />
      <HeaderOne
        btnStyle="btn-small round btn-icon"
        HeaderSTyle="especial-header"
      />

      <div className="rwt-advance-tab-area rn-section-gap">
        <div className="container">
          <div className="row mb--40">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Espaço Wolcoff"
                title="Oferecemos para você!"
                description="Oferecemos cuidados de saúde integrados, bem-estar holístico e salas de cursos para desenvolvimento pessoal e profissional"
              />
            </div>
          </div>
          <TabOne />
          {/* <AdvanceTabOne /> */}
        </div>
      </div>
      <FooterTwo />
      <Copyright />
    </>
  );
};
export default Especialidades;
