import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import Company from "./pages/Company";
import Contato from "./pages/Contato";

import TagList from "./pages/TagList";
import AboutUs from "./pages/AboutUs";
import Especialidades from "./pages/Especialidades";
import Equipe from "./pages/Equipe";

import Timeline from "./elements/timeline/Timeline";

import BlogGridView from "./components/blog/BlogGridView";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";

// Import Css Here
import "./assets/scss/style.scss";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={Company}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/contato"}`}
            exact
            component={Contato}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/parceiros"}`}
            exact
            component={Equipe}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/especialidades"}`}
            exact
            component={Especialidades}
          />

          {/* Blog Part  */}
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            exact
            component={BlogGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-detalhes/:id"}`}
            exact
            component={BlogDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
            exact
            component={TagList}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/salas-de-aula"}`}
            exact
            component={Timeline}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/sobre"}`}
            exact
            component={AboutUs}
          />

          <Route
            component={Error}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
