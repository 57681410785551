import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} || Espaço Wolcoff</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Descubra o Espaço Wolcoff, seu refúgio de tranquilidade. Oferecemos odontologia, tratamentos de corpo e mente, estomaterapia e muito mais para seu bem-estar." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


