import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const AdvanceTabOne = () => {
  return (
    <div>
      <Tabs>
        <div className="row row--30">
          <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
            <div className="advance-tab-button advance-tab-button-1">
              <TabList className="tab-button-list">
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Odontologia</h4>
                    <p className="description">
                      O espaço Wolcoff oferece odontologia completa. Para
                      promover a saúde bucal e bem-estar geral.
                    </p>
                  </div>
                </Tab>

                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Corpo e Mente</h4>
                    <p className="description">
                      Instituto Heuko, com massoterapia, hipnoterapia e
                      ozonioterapia, realizadas por terapeutas e profissionais
                      especializados.
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Estomaterapia</h4>
                    <p className="description">
                      Oferecemos especialistas em estomaterapia para cuidados em
                      estomas, feridas, e podiatria. Além disso, fornecemos
                      cursos de atualização para profissionais de saúde e
                      consultoria em prevenção de complicações pós-operatórias e
                      auditoria de contas relacionadas ao tratamento de feridas.
                    </p>
                  </div>
                </Tab>
                {/* <Tab>
                  <div className="tab-button">
                    <h4 className="title">Estética Facial com Kamila Santos</h4>
                    <p className="description">
                      Oferecemos Estética Facial com Kamila, Seus serviços
                      incluem Design de Sobrancelhas Personalizado, Limpeza de
                      Pele, Brow Lamination, Lash Lifting e Dermaplaning. Sua
                      missão é realçar sua beleza de forma natural. Desfrute de
                      uma experiência única!
                    </p>
                  </div>
                </Tab> */}

                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Sala de aula</h4>
                    <p className="description">
                      No Espaço Wolcoff, não apenas nos preocupamos com o seu
                      bem-estar físico, mas também estamos comprometidos em
                      apoiar o seu desenvolvimento pessoal e profissional. É por
                      isso que oferecemos sala de aula moderna e bem equipadas
                      para locação, proporcionando o ambiente perfeito para a
                      disseminação do conhecimento e o crescimento pessoal.
                    </p>
                  </div>
                </Tab>
              </TabList>
            </div>
          </div>
          <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="advance-tab-content advance-tab-content-1">
              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/wolcoff/home/odontologia.png"
                        alt="Espaço Wolcoff Image"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/wolcoff/home/heuko.png"
                        alt="Espaço Wolcoff Image"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/wolcoff/home/cursos.png"
                        alt="Espaço Wolcoff Image"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/wolcoff/home/kamila.png"
                        alt="Espaço Wolcoff Image"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/wolcoff/logo2.png"
                        alt="Espaço Wolcoff Image"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./images/wolcoff/logo2.png"
                        alt="Espaço Wolcoff Image"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};
export default AdvanceTabOne;
