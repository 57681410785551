import React from "react";
import Separator from "../separator/Separator";
import SEO from "../../common/SEO";
import SectionTitle from "../sectionTitle/SectionTitle";
import TimelineOne from "./TimelineOne";
import HeaderOne from "../../common/header/HeaderOne";
import FooterTwo from "../../common/footer/FooterTwo";
import Copyright from "../../common/footer/Copyright";

const Timeline = () => {
  return (
    <>
      <SEO title="Cursos" />
      <HeaderOne
        btnStyle="btn-small round btn-icon"
        HeaderSTyle="especial-header"
      />
      <div className="main-content">
        {/* Start Timeline Area  */}
        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Ambiente para aprimoramento e sala de aula para locação"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <TimelineOne />
              </div>
            </div>
          </div>
        </div>
        {/* End Timeline Area  */}
      </div>
      <FooterTwo />
      <Copyright />
    </>
  );
};
export default Timeline;
