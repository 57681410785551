import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SlpitOne = () => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div
              style={{ marginLeft: "10px" }}
              className="thumbnail image-left-content"
            >
              <img
                src="./images/wolcoff/banner/bemvindoespacowolcoff.png"
                alt="split Images"
              />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h5 className="title new-title-home">
                  Bem-vindo ao Espaço Wolcoff <br />
                  Saúde e Bem-estar
                </h5>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  No Espaço Wolcoff, acreditamos que o bem-estar é uma jornada
                  que envolve cuidados holísticos para o corpo e a mente. Nossa
                  clínica foi fundada com a visão de proporcionar um ambiente
                  acolhedor e integrado, onde nossos pacientes possam encontrar
                  uma variedade de serviços de saúde de alta qualidade para
                  atender às suas necessidades individuais.
                </p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              ></ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              ></ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlpitOne;
