import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
  {
    id: "1",
    date: "Step-1",
    title: "Sala de aula",
    description:
      "No Espaço Wolcoff, não apenas nos preocupamos com o seu bem-estar físico, mas também estamos comprometidos em apoiar o seu desenvolvimento pessoal e profissional. É por isso que oferecemos sala de aula modernas e bem equipadas para locação, proporcionando o ambiente perfeito para a disseminação do conhecimento e o crescimento pessoal.",
    image: "SALA-DE-CURSOS.png",
    workingStep: [
      {
        stepTitle: "Gain your works",
        stepDescription: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      },
      {
        stepTitle: "Working process",
        stepDescription: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      },
    ],
  },
  {
    id: "2",
    date: "Step-2",
    title: "Nossa sala de aula",
    description:
      "Nossa sala foram projetadas com versatilidade em mente. São ideais para uma gama variada de eventos, incluindo workshops, palestras, seminários, reuniões de negócios e muito mais. Dispomos de recursos audiovisuais, acesso à internet de alta velocidade e equipamentos de apresentação para garantir que suas sessões de treinamento sejam bem-sucedidas, além de sala de prática com vídeo integrado à sala de aula para transmissão em tempo real e ao vivo dos procedimentos. Ambiente Confortável: Criamos um ambiente acolhedor e confortável, onde os participantes se sentem à vontade para aprender, colaborar e se inspirar. A atmosfera tranquila e relaxante da nossa clínica contribui para um aprendizado eficaz e integrativo. Localização Estratégica: Nossa clínica está convenientemente localizada, facilitando o acesso para os participantes. Estamos comprometidos em tornar a experiência de ensino e aprendizado uma imersão dos sentidos. Na área odontológica e estética, contamos com a possibilidade de integração teórica à prática no centro cirúrgico, o que reforça a imersão educacional em seu mais alto grau de profundidade.",
    image: "NOSSAS-SALAS.png",
    workingStep: [
      {
        stepTitle: "Follow this process",
        stepDescription: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      },
      {
        stepTitle: "Working experience",
        stepDescription: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      },
    ],
  },
  {
    id: "3",
    date: "Step-2",
    title: "A quem se destina nossa sala de aula?",
    description:
      "Profissionais de Saúde: Nossa sala são ideais para profissionais de saúde que desejam ministrar cursos, workshops ou treinamentos para colegas ou pacientes. Educadores: Professores e educadores podem aproveitar nossos espaços para realizar aulas, seminários e atividades de formação. Empresas e Empreendedores: Empresas e empreendedores podem utilizar nossa sala para reuniões estratégicas, treinamentos de funcionários ou eventos de networking.",
    image: "QUEM-PODE-USAR.png",
    workingStep: [
      {
        stepTitle: "Follow this process",
        stepDescription: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      },
      {
        stepTitle: "Working experience",
        stepDescription: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      },
    ],
  },
  {
    id: "4",
    date: "Step-2",
    title: "Como reservar uma sala?",
    description:
      "Reservar nossa sala de aula é simples. Basta entrar em contato conosco para verificar a disponibilidade, discutir suas necessidades específicas e fazer sua reserva. Estamos aqui para ajudar você a criar a experiência de aprendizado ou reunião perfeita.",
    image: "COMO-RESERVAR.png",
    workingStep: [
      {
        stepTitle: "Follow this process",
        stepDescription: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      },
      {
        stepTitle: "Working experience",
        stepDescription: "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      },
    ],
  },
];

const TimelineOne = () => {
  return (
    <div className="rn-timeline-wrapper timeline-style-one position-relative">
      <div className="timeline-line"></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <div className="single-timeline mt--50" key={index}>
          <div className="timeline-dot">
            <div className="time-line-circle"></div>
          </div>
          <div className="single-content">
            <div className="inner">
              <div className="row row--30 align-items-center">
                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                  <div className="content">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <h2 className="title">{data.title}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <p className="description">{data.description}</p>

                      <a href="https://api.whatsapp.com/send?phone=5541997679426&text=Ol%C3%A1,%20Gostaria%20de%20saber%20mais%20sobre%20a%20Espa%C3%A7o%20Wolcoff." className="btn-default btn-icon" target="__blank">Saiba mais</a>
                    </ScrollAnimation>

                    <div className="row row--30">
                      {data.workingStep.map((data, index) => (
                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                          <div className="working-list"></div>
                        </div>
                      ))}
                    </div>
                    <div className="read-morebtn">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      ></ScrollAnimation>
                    </div>
                  </div>
                </div>
                <div className="order-1 order-lg-2 col-lg-6">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={`./images/wolcoff/banner/${data.image}`}
                      alt="Wolcoff"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* End Single Timeline  */}
    </div>
  );
};

export default TimelineOne;
