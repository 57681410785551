import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import img1 from "../../assets/images/home/espacow.png";
import img2 from "../../assets/images/home/instituto.png";
import img3 from "../../assets/images/home/kamila-img.png";
import img4 from "../../assets/images/home/odontologia.png";
import img5 from "../../assets/images/home/specialist.png";
import img6 from "../../assets/images/home/nutrimairon.png";

const TabOne = () => {
  const [imageTab, setImageTab] = useState(img4);
  const [getImgHref, setImgHref] = useState("");

  useEffect(() => {
    if (imageTab === img5) {
      setImgHref("https://www.facebook.com/specialistcarecuritiba/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0");
    } else if (imageTab === img1) {
      setImgHref("/");
    } else if (imageTab === img2) {
      setImgHref("https://www.facebook.com/nestorheukoinstituto/");
    } else if (imageTab === img6) {
      setImgHref("https://nutrimairon.com.br/");
    }
  }, [imageTab]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <a target="__blank" href={getImgHref}>
                  <img
                    className="radius-small"
                    src={imageTab}
                    alt="Wolcoff"
                    usemap="#image-map"
                  />
                </a>

                {imageTab === img4 && (
                  <map name="image-map">
                    <area target="_blank" alt="Odontologia Wolcoff" title="Odontologia Wolcoff" href="https://www.odontologiawolcoff.com.br/" coords="132,2,407,261" shape="rect" />
                    <area target="_blank" alt="Dra. Natalie Temporão" title="Dra. Natalie Temporão" href="https://natalietemporao.com.br/" coords="9,265,259,364" shape="rect" />
                    <area target="_blank" alt="Dra. Giovanna Simião" title="Dra. Giovanna Simião" href="https://www.instagram.com/dra.gisimiao" coords="265,264,522,365" shape="rect" />
                  </map>
                )}
              </div>
              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="rn-default-tab">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab onClick={() => setImageTab(img4)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setImageTab(img4)}>
                            Odontologia
                          </button>
                        </div>
                      </Tab>
                      <Tab onClick={() => setImageTab(img2)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setImageTab(img2)}>
                            Corpo e Mente
                          </button>
                        </div>
                      </Tab>
                      <Tab onClick={() => setImageTab(img5)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setImageTab(img5)}>
                            Estomaterapia
                          </button>
                        </div>
                      </Tab>

                      <Tab onClick={() => setImageTab(img6)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setImageTab(img6)}>
                            Saúde e Bem-Estar com Mairon
                          </button>
                        </div>
                      </Tab>

                      {/* <Tab onClick={() => setImageTab(img3)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setImageTab(img3)}>
                            Estética Facial
                          </button>
                        </div>
                      </Tab> */}
                      <Tab onClick={() => setImageTab(img1)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setImageTab(img1)}>
                            Sala de aula
                          </button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            O espaço Wolcoff oferece odontologia completa. Para
                            promover a saúde bucal e bem-estar geral.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Instituto Heuko, com massoterapia, hipnoterapia e
                            ozonioterapia, realizadas por terapeutas e
                            profissionais especializados.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Oferecemos especialistas em estomaterapia para
                            cuidados em estomas, feridas, e podiatria. Além
                            disso, fornecemos cursos de atualização para
                            profissionais de saúde e consultoria em prevenção de
                            complicações pós-operatórias e auditoria de contas
                            relacionadas ao tratamento de feridas.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Eu sou Mairon, um entusiasta da saúde, nutrição e esportes, com mais de 20 anos de experiência. Graduado em Educação Física e Nutrição, com especializações em Nutrição Esportiva e Fisiologia do Esporte, ofereço consultoria esportiva e orientação nutricional personalizada. Esteja você buscando promover a saúde, perder peso, ganhar massa muscular, passar por cirurgia bariátrica, adotar uma dieta vegana ou enfrentar desafios durante a gestação, estou aqui para ajudar. Minha missão é guiá-lo, independentemente da idade ou ponto de partida, em direção a uma vida mais saudável. Junte-se a mim nessa jornada emocionante para alcançar o seu melhor.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    {/* <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Oferecemos Estética Facial com Kamila, Seus serviços
                            incluem Design de Sobrancelhas Personalizado,
                            Limpeza de Pele, Brow Lamination, Lash Lifting e
                            Dermaplaning. Sua missão é realçar sua beleza de
                            forma natural. Desfrute de uma experiência única!
                          </p>
                        </div>
                      </div>
                    </TabPanel> */}

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            No Espaço Wolcoff, não apenas nos preocupamos com o
                            seu bem-estar físico, mas também estamos
                            comprometidos em apoiar o seu desenvolvimento
                            pessoal e profissional. É por isso que oferecemos
                            sala de aula modernas e bem equipadas para locação,
                            proporcionando o ambiente perfeito para a
                            disseminação do conhecimento e o crescimento
                            pessoal.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabOne;
